var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"product-add-wrapper"},[_c('validation-observer',{ref:"addCustomerFlowValidation"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Sur name"}},[_c('validation-provider',{attrs:{"name":"Sur name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.surname),callback:function ($$v) {_vm.$set(_vm.customerFlow, "surname", $$v)},expression:"customerFlow.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Guest ID"}},[_c('validation-provider',{attrs:{"name":"Guest ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.guestId),callback:function ($$v) {_vm.$set(_vm.customerFlow, "guestId", $$v)},expression:"customerFlow.guestId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Res code"}},[_c('validation-provider',{attrs:{"name":"Res code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.resCode),callback:function ($$v) {_vm.$set(_vm.customerFlow, "resCode", $$v)},expression:"customerFlow.resCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Pax"}},[_c('validation-provider',{attrs:{"name":"Pax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.pax),callback:function ($$v) {_vm.$set(_vm.customerFlow, "pax", $$v)},expression:"customerFlow.pax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.email),callback:function ($$v) {_vm.$set(_vm.customerFlow, "email", $$v)},expression:"customerFlow.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Room type"}},[_c('validation-provider',{attrs:{"name":"Room type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.roomType),callback:function ($$v) {_vm.$set(_vm.customerFlow, "roomType", $$v)},expression:"customerFlow.roomType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Check in time"}},[_c('validation-provider',{attrs:{"name":"Check in time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true,dateFormat: 'Y/m/d H:i'}},model:{value:(_vm.customerFlow.checkInTime),callback:function ($$v) {_vm.$set(_vm.customerFlow, "checkInTime", $$v)},expression:"customerFlow.checkInTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Arrival Date"}},[_c('validation-provider',{attrs:{"name":"Arrival Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{model:{value:(_vm.arrivalDate),callback:function ($$v) {_vm.arrivalDate=$$v},expression:"arrivalDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Departure Date"}},[_c('validation-provider',{attrs:{"name":"Departure Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{model:{value:(_vm.departureDate),callback:function ($$v) {_vm.departureDate=$$v},expression:"departureDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Feed back"}},[_c('validation-provider',{attrs:{"name":"Feed back","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.feedback),callback:function ($$v) {_vm.$set(_vm.customerFlow, "feedback", $$v)},expression:"customerFlow.feedback"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Note"}},[_c('validation-provider',{attrs:{"name":"Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.customerFlow.note),callback:function ($$v) {_vm.$set(_vm.customerFlow, "note", $$v)},expression:"customerFlow.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Featured Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('vue-upload-multiple-image',{attrs:{"browse-text":"Or choose","drag-text":"Drag image (multiple)","drop-text":"Drop your images","show-primary":false},on:{"upload-success":_vm.uploadImageSuccess,"before-remove":_vm.beforeRemove,"edit-image":_vm.editImage}})],1),_c('b-media-body',[_c('div',{staticClass:"mt-5 ml-2"},[_c('small',{staticClass:"text-muted"},[_vm._v("Required image resolution 850x225, image size 5mb.")]),_c('b-card-text',{staticClass:"my-50"},[_vm._v(" "+_vm._s('banner.jpg')+" ")])],1)])],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.add}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'asset-manager' },"variant":"outline-secondary"}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }