import { ref } from '@vue/composition-api'

export default function addCustomerFlow() {
  const customerFlow = ref({})
  const imageIDs = ref([])
  const arrivalDate = ref(null)
  const departureDate = ref(null)
  return {
    customerFlow,
    imageIDs,
    arrivalDate,
    departureDate,
  }
}
